import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import MainMenu from '../components/MainMenu'
import RestrictionsMessage from '../components/RestrictionsMessage'

import { cubicBezier, MQ } from '../assets/styles/constants'

class PageCategoryFrontpage extends React.Component {
  render() {
    const products = this.props.data.products
    return (
      <React.Fragment>
        <MainMenu />
        <RestrictionsMessage />
        <ProductList>
          {products.edges.length > 0 &&
            products.edges.map(product => {
              if (product.node.featured_media === null) {
                return null
              }

              const image =
                product.node.featured_media.localFile !== null
                  ? product.node.featured_media.localFile.childImageSharp.fluid
                  : product.node.featured_media.source_url

              const imageIsAvailableLocal = product.node.featured_media.localFile !== null

              return (
                <Product
                  className="product"
                  key={`product-${product.node.id}`}
                  to={`/collection/${product.node.parent_element.slug}/${product.node.slug}`}
                >
                  <Media className="product-media">
                    <svg width="352px" height="353px" viewBox="0 0 352 353">
                      <path
                        d="M290.158438,43.4215491 C259.407164,16.9246568 219.371878,0.905 175.595,0.905 C78.6165594,0.905 0,79.5215594 0,176.5 C0,273.478441 78.6165594,352.095 175.595,352.095 C272.573441,352.095 351.19,273.478441 351.19,176.5 C351.19,158.839461 348.582817,141.787862 343.732346,125.709099 L342.87774,126.340805 C347.634536,142.227588 350.19,159.065364 350.19,176.5 C350.19,272.926156 272.021156,351.095 175.595,351.095 C79.1688441,351.095 1,272.926156 1,176.5 C1,80.0738441 79.1688441,1.905 175.595,1.905 C219.040882,1.905 258.780432,17.7736952 289.33376,44.0311972 L290.158438,43.4215491 Z"
                        fill="#FFFFFF"
                      />
                    </svg>
                    {imageIsAvailableLocal ? (
                      <Img
                        fluid={image}
                        critical
                        style={{
                          userSelect: 'none',
                          width: '100%',
                          display: 'block',
                        }}
                        alt={product.node.title}
                      />
                    ) : (
                      <img
                        src={image}
                        style={{
                          userSelect: 'none',
                          width: '100%',
                          display: 'block',
                        }}
                        alt={product.node.title}
                      />
                    )}
                  </Media>
                  <Content>
                    <ProductTitle dangerouslySetInnerHTML={{ __html: product.node.title }} />
                    <ProductLink className="product-link">
                      <span>Go to page</span>
                      <svg width="17px" height="13px" viewBox="0 0 17 13">
                        <path
                          d="M11.4476024,0.854471502 C11.2160051,0.613842833 10.8305494,0.613842833 10.5908542,0.854471502 C10.3592569,1.08697082 10.3592569,1.47392774 10.5908542,1.70588511 L14.9350587,6.06700876 L0.599777707,6.06700876 C0.26560813,6.06755072 0,6.3341933 0,6.66966435 C0,7.0051354 0.26560813,7.28044928 0.599777707,7.28044928 L14.9350587,7.28044928 L10.5908542,11.6334436 C10.3592569,11.8740723 10.3592569,12.2615711 10.5908542,12.4935285 C10.8305494,12.7341572 11.2165449,12.7341572 11.4476024,12.4935285 L16.8202286,7.09997778 C17.0599238,6.86747846 17.0599238,6.48052154 16.8202286,6.24856418 L11.4476024,0.854471502 Z"
                          fill="#FFFFFF"
                        />
                      </svg>
                    </ProductLink>
                  </Content>
                </Product>
              )
            })}
        </ProductList>
      </React.Fragment>
    )
  }
}

export default PageCategoryFrontpage

export const pageQuery = graphql`
  query($id: String!) {
    products: allWordpressPage(
      filter: { parent_element: { id: { eq: $id } }, template: { eq: "page-category-product.php" } }
      sort: { fields: menu_order, order: ASC }
    ) {
      edges {
        node {
          slug
          title
          id
          parent_element {
            slug
          }
          featured_media {
            source_url
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

const ProductList = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  @media screen and (${MQ.lg}) {
    &:hover {
      .product {
        filter: blur(5px);

        &:hover {
          filter: blur(0);
        }
      }
    }
  }
`

const Product = styled(Link)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  position: relative;
  z-index: 1;
  transition: all 0.5s ${cubicBezier};
  max-width: 600px;

  @media screen and (${MQ.lg}) {
    &:hover {
      z-index: 2;
      background-color: #191919;
      transform: scale(1.2);

      .product-media {
        svg {
          opacity: 1;
          transform: rotate(15deg);
        }
      }

      .product-link {
        opacity: 1;
        bottom: 0;
        transition: all 0.5s ${cubicBezier} 0.5s;
      }
    }
  }
`

const Media = styled.div`
  width: 100%;
  position: relative;
  padding: 0 45px;
  margin-bottom: 120px;

  @media screen and (${MQ.lg}) {
    margni-bottom: 180px;
  }

  > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -176px;
    margin-left: -176px;
    transition: all 1s cubic-bezier(0.17, 0.67, 0.22, 0.99);
    transform: rotate(-45deg);
    opacity: 0;
  }
`

const Content = styled.div`
  text-align: center;
`

const ProductTitle = styled.span`
  display: block;
  font-size: 20px;
  letter-spacing: 4px;
  text-transform: uppercase;

  @media screen and (${MQ.lg}) {
    font-size: 35px;
  }
`

const ProductLink = styled.span`
  margin-top: 40px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: 0;
  bottom: -15px;

  > svg {
    margin-left: 40px;
  }
`
